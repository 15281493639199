import React from "react"

const Copyright = () => {
  return (
    <div
      style={{
        background: "black",
        padding: "0.5rem",
        color: "white",
        textAlign: "center",
      }}
    >
      <p style={{ fontSize: "10px" }}>
        McCully11X &copy; 2022 All Rights Reserved
      </p>
    </div>
  )
}

export default Copyright
